import styled from 'styled-components';
import { Helmet } from 'react-helmet-async';
import { heading, metaDescription, metaKeywords, title } from 'constants/meta';
import MainTemplate from 'components/templates/MainTemplate';
import DisableSSR from 'components/atoms/DisableSSR';
import Menu, { MenuLink } from 'components/atoms/Menu';
import SignOutMenuItem from './SignOutMenuItem';

const Title = styled.h1`
  font-size: 2em;
  font-weight: normal;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;

  @media (min-width: ${({ theme }) => theme.breakpoints[1]}em) {
    flex-direction: row;
    margin: 0 -0.625em;
  }
`;

const LeftColumn = styled.aside`
  margin-bottom: 1em;

  @media (min-width: ${({ theme }) => theme.breakpoints[1]}em) {
    width: 25%;
    flex: none;
    padding: 0 0.625em;
  }
`;

const RightColumn = styled.main`
  @media (min-width: ${({ theme }) => theme.breakpoints[1]}em) {
    flex: 1 1 auto;
    padding: 0 0.625em;
  }
`;

const ProfileTemplate = ({ children }) => (
  <DisableSSR>
    <MainTemplate>
      <Helmet>
        <title>{title.profile}</title>
        <meta name="keywords" content={metaKeywords.profile} />
        <meta name="description" content={metaDescription.profile} />
      </Helmet>
      <Title>{heading.profile}</Title>
      <Wrapper>
        <LeftColumn>
          <Menu withActive>
            <MenuLink to="/profile/orders">Мои заказы</MenuLink>
            <MenuLink to="/profile/bookmarks">Мои закладки</MenuLink>
            <MenuLink to="/profile/settings">Настройки</MenuLink>
            <SignOutMenuItem />
          </Menu>
        </LeftColumn>
        <RightColumn>{children}</RightColumn>
      </Wrapper>
    </MainTemplate>
  </DisableSSR>
);

export default ProfileTemplate;
