import { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Mutation } from 'react-apollo';
import styled from 'styled-components';
import signoutMutation from 'graphql/mutations/signout.graphql';
import { MenuLink } from 'components/atoms/Menu';
import Button from 'components/atoms/Button';

const StyledMenuLink = styled((props) => <MenuLink {...props} as={Button} />)`
  border-radius: 0;
  width: 100%;
  text-align: left;
`;

@withRouter
class SignOutMenuItem extends Component {
  handleCompleted = () => {
    this.props.history.push('/');
  };

  handleError = () => {
    window.alert('Произошла ошибка. Попробуйте позднее.');
  };

  render() {
    return (
      <Mutation
        mutation={signoutMutation}
        onCompleted={this.handleCompleted}
        onError={this.handleError}
      >
        {(signout, { loading }) => (
          <StyledMenuLink onClick={() => (loading ? () => {} : signout())}>
            {loading ? 'Загрузка...' : 'Выйти'}
          </StyledMenuLink>
        )}
      </Mutation>
    );
  }
}

export default SignOutMenuItem;
